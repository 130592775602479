import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout/Layout";
import SEO from "../components/seo/Seo";
import FAQ from "../components/faq-page/FAQPage";
import i18nContext from "../components/i18n/i18n.context";
import Page from "../components/page/Page";

function FAQPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO keywords={["gatsby", "react"]} location={location} title="FAQ" />
        <Page>
          <FAQ />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

FAQPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default FAQPage;
